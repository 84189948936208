

/* body, html {
    height: 100%;
    margin: 0;
  } */

.container-beginning{
    width: 100%;
    min-height: 100vh;
    padding: 4%;
    background: linear-gradient(to right, #090628, #020138);
    background-color: blue;

    display: flex;
    justify-content: center;
    align-items: center;
}


.centered-beginning{
    display: block;
    max-width: 500px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #070522, #02012b);
    border-radius: 8px;
    border: 1.5px solid #1b1b55;
    padding: 20px;
    /* border: 2px solid yellow; */
}


  
  .login .title{
    font-size: 40px;
    font-weight: 600;
    background: linear-gradient(90deg, #f7f7f7, #8282c8 );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  
  .login .par{
    margin-top: 10px;
    margin-bottom: 35px;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.587);
  }
  
  .button{
    width: 75%;
    border: 0.7px solid rgb(164, 151, 237);
    border-radius: 8px;
    color: white;
    background: none;
    padding: 15px;
  }
  
  .input{
    background: none;
    border: 0.7px solid rgb(164, 151, 237);
    color: white;
    /* background-color: red; */
  }
  
  .right {
    flex: 1;
  }
  
  /* Media query for screens less than 800px width */
  @media (max-width: 950px) {
    .container {
      flex-direction: column; /* Stack the divs vertically */
    }
  
    .left {
      flex: 1; /* Left div will now fill the entire width */
    }
  
    .right {
      display: none; /* Hide the right div */
    }
  }
  
  .buttonlogo{
    width: 24px;
    height: 24px;
  }
  
  .buttoncontent{
    display: flex;
    align-items: center;
  }
  
  .buttoncontent p{
    flex: 1; /* This will make the text take up the remaining space */
    text-align: center;
    font-size: 16px;
  }
  
  .submitbutton{
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: none;
    background-color: #8282c8;
    color: white;
    font-weight: 500;
    font-size: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .divider{
    width: 100%;
    background-color: red;
  }