
.d-container {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  color: white;
}

.left-panel {
  width: 70%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.left-content {
  flex: 1;
  overflow-y: auto;
  /* background-color: red; */
  /* margin-top: 10vh; Offset by 10% of the viewport height */
}

.right-panel {
  width: 30%;
  /* background-color: lightblue; */
  /* padding: 20px; */
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100vh;
  overflow-y: auto;
}

.banner {
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #07041e;
  padding: 10px 50px;
  display: block;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.5)
}


.banner .subject{
  font-size: 22px;
  font-weight: 600;
}



.d-profile {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.d-profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #7f88d6;
  color: #ced0ef;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
}

.d-profile-left {
  display: flex;
  align-items: center;
}

.d-stack {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.d-stack-name{
  font-size: 15px;
}

.d-stack-email{
  font-size: 15px;
  color: #ced0ef;
}

.d-profile-date{
  font-size: 15px;
  color: #ced0ef;
}

.d-stack p {
  margin: 0;
}

.d-profile p {
  margin: 0;
}


.d-email-content{
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.d-email-subject{
  font-size: 18px;
  margin-bottom: 17px;
}

.d-email-message{
  font-size: 12px; 
  color: #ced0ef;
  /* max-width: 95%; */
  word-wrap: break-word;
  margin: 0 auto;
}



.d-email-component{
  overflow-y: auto;
  height: 90vh;
}

.d-email-component::-webkit-scrollbar { width: 7px; opacity: 0; }

.d-email-component::-webkit-scrollbar-thumb { border-radius: 5px; background-color: #777; }

.d-each-email{
/* border: 1px solid rgba(198, 198, 198, 0.875); */
padding: 0 30px;
}

.d-one-email{
/* border: 1px solid rgba(198, 198, 198, 0.875); */
}


.d-quill{
  display: flex;
  width: 100%;
  height: 35vh;
}

.d-quill-left{
  display: flex;
  justify-content: center;
  width: 60px;
}

.d-quill-right{
  flex-grow: 1;
}

.d-vert-bar{
  width: 100%;
  height: 30px;
  background-color: #1f1f1f;
  display: flex;
  align-items: center;
}


.d-add-email-button {
  width: 50px;
  height: 50px;
  color: #ced0ef;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.d-email-item{
  font-size: 13px;
  padding: 5px;
  background-color: #2a2929;
  color: #ced0ef;
  border-radius: 4px;
}


.d-email-btn-icon{
  font-size: 20px;
  color: #505fec;
}


.d-input-email{
  background-color: transparent;
  /* border: #3c3a3a; */
  color: #ced0ef;
}


.d-quill-block{
  background-color: red;
}

.d-react-quill{
  background-color: purple;
}

.d-editor{
padding: 27px;
/* background-color: red; */
}

.d-editor-to{
display: flex;
height: 30px;
width: 100%;
border-radius: 8px;
align-items: center;
padding-left: 10px;
}

.d-editor-subject{
display: flex;
height: 30px;
width: 100%;
border-radius: 8px;
align-items: center;
padding-left: 10px;
}

.d-editor-to-input{
width: 100%;
height: 100%;
border: none;
margin-left: 5px;
/* background-color: red; */
}


.d-button-container{
display: flex;
justify-content: flex-end;
/* height: 10px; */
/* background-color: red; */
}

.d-editor-button{
position: relative;
z-index: 1;
padding: 10px;
margin: 100px;
background-color: rgba(0, 110, 255, 0.743);
color: white;
border: none;
border-radius: 8px;
margin-top: -50px;
margin-right: 15px;
}

.d-paperplane{
margin-left: 2px;
}







.d-profile-2 {
  width: 100%;
  /* height: 100px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 10px;
}

.d-profile-picture-2 {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: #7f88d6;
  color: #ced0ef;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 500;
}

.d-profile-left-2 {
  display: flex;
  align-items: center;
  width: 100%;
  /* background-color: RED; */
}

.buttons-vert{
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
  margin-bottom: 40px;
  width: 100%;
  padding-top: 30px;
}

.buttons-vert button{
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #7f88d6;
  font-weight: 500;
  color: #dddeed;
  margin-right: 10px;
}

.ant-alert-message{
  color: rgba(0, 0, 0, 0.839);
}


.shopify-widget{
  width: 90%;
}



.customer-information{
  border: 0.5px solid #0f024e;
  border-radius: 10px;
  /* background-color: #070418; */
  background-color: #080226;
  padding: 20px;
  margin-bottom: 20px
}


.top-section{
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 0.5px solid #0f024e;
}

.order-summary{
  display:flex;
  justify-content: space-between;
}

.order-list{
  width: 100%;
  display:flex;
  flex-direction: column;
  gap: 20px;
}

.order-price{
  display:flex;
  justify-content: space-between;
}

.order-item{
  border: 0.5px solid #0f024e;
  border-radius: 10px;
  background-color: #080226;
  padding: 20px;
  display:flex;
  flex-direction: column;
  gap: 20px;
}

.order-item a{
  display:block;
  text-decoration: none;
  padding: 10px;
  color: white;
  border-radius: 10px;
  /* width: fit-content; */
  width: 90%;
  text-align: center;
  background-color: #7f88d6;
  margin: 0 auto;
}

.order-item a:hover{
  background-color: #8b94ea;
}

.shipping-address{
  display:flex;
  flex-direction: column;
  gap: 10px;
}

.shipping-address h4{
  margin-bottom: 10px;
}


.ai-widget{
  border: 0.5px solid #0f024e;
  border-radius: 10px;
  background-color: #080226;
  padding: 20px;
  margin-bottom: 20px;
  width: 90%;
}

.widget-body{
  display:flex;
  flex-direction: column;
  gap: 20px;
}

.widget-top-section{
  display:flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}


.shopify-logo, .ai-logo{
  display:flex;
  gap: 20px;
  align-items: center;
}

.shopify-logo img{ width: 50px; }

.widget-contents{
  display:flex;
  flex-direction: column;
  gap: 20px;
}

.order-details{
  display: flex;
  justify-content: space-between;
  align-items:center;
}

.ai-widget-content{
  display:flex;
  flex-direction: column;
  gap: 20px;
}

.ai-widget-header{
  font-weight: bold;
  display: flex; 
  align-items: center;
  gap: 20px;
  font-size: 22px;
  text-align: center;
}


.ai-widget-insight > h3{
  margin-bottom: 10px;
}

.ai-widget-insight > p{
  width: 95%;
  margin: 0 auto;
}

.ai-widget-commands{
  display:flex;
  gap: 10px;
  align-items: center;
}

.ai-widget-command{
  display:flex;
  background-color: #7f88d682;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  text-transform: capitalize;
}


.ai-history{
  display:flex;
  flex-direction: column;
  gap: 10px;
}

.accordion-button{
  width: 100%;
  padding: 10px;
  background-color: #04082d;
  border: 0.5px solid #0f024e;
}

.accordion-content > p{
  padding: 10px 20px;
}

.accordion-item{
  overflow-y: auto;
}

.accordion-content > textarea{
  color:white;
  padding: 10px 20px;
  width: 100%;
  border: none;
  background-color: #080226;
  resize: none;
  font-size:14px;
  /* line-height: 1.1; */
}

.accordion-content > textarea::-webkit-scrollbar { width: 7px; opacity: 0; }

.accordion-content > textarea::-webkit-scrollbar-thumb { border-radius: 5px; background-color: #777; }


.accordion-item {
  overflow: hidden;
}

.accordion-content {
  max-height: 0;
  transition: max-height 0.5s ease-in-out; /* Adjust the animation duration as needed */
}

.accordion-item.active .accordion-content {
  max-height: 200px;
}