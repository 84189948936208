.picker{
    width: 100%;
    margin-left: 0%;
    margin-top: 20px;
    margin-left: 16px;
}


.stats{
    width: 100%;
    margin-top: 20px;
    height: 20vh;
}

.box{
    float: left;
    width: 22%;
    margin: 12px;
    padding: 15px 25px;
    background-color: #040110; /* #07021c */
    /* background-color: red; */
    border-radius: 8px;
}

.text{
    /* background-color: red; */
    justify-content: space-between;
    overflow: hidden;
}

.main-text{
    display: inline-block;
    font-size: 30px;
    font-weight: 600;
}

.descriptor{
    color: gray;
    font-size: 14px;
}

.change{
    display: inline-block;
    margin-left: 6px;
    color: rgba(209,195,242,255);
    /* color: rgb(1, 177, 1); */
}

.text-main{
    display: inline-block;
    width: 75%;
    /* background-color: green; */
}

.trend{
    display: inline-block;
    float: right;
    height: 40px;
    width: 40px;
    margin-top: 2px;
    margin-right: 5px;
    border-radius: 4px;
}


.centered{
    /* color: rgb(1, 177, 1); */
    margin: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


#color-indicator2{
    background-color: #d1c3f230;
    color: #D1C3F2;
    /* background-color: #17342b; */
    /* background-color: #372127; */
}

#color-indicator-2{
    /* color: rgb(1, 177, 1); */
    /* color: #fa424a; */
    color: rgba(116,56,254,255)
}

#color-indicator2-2{
    /* background-color: #17342b; */
    /* background-color: #372127; */
    background-color: #7338fe2f;
}


@media (max-width:1175px){

    .box{
        width: 43%;
        /* background-color: blue; */
    }

    .stats{
        height: 37vh;
    }
}

@media (max-width: 567px){

    .stats{
        height: 76vh;
    }

    .box{
        width: 89%;
        /* background-color: pink; */
    }

    .picker{
        margin-left: 5%;
    }
}

.graph-container{
    width: 100%;
    height: 400px;
    border-radius: 8px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.dark .graph-container{
    background-color: #07021c;
}

.graph-container .big-graph{
    width: 96%;
    height: 300px;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-right: 2%;
}

.graph-container .h-bar {
    height: 70px;
    /* background-color: blue; */
}

.h2 {
    padding-top: 20px;
    margin-left: 20px;
    font-size: 18px;
    font-weight: 500;
}

.h1{
    margin-left: 20px;
    margin-top: 0px;
    font-weight: 600;
    margin-bottom: 5px;
}

.span {
    font-size: 22px;
    color: rgba(101, 101, 101, 0.677);
}

.dark .span{
    color: rgba(255, 255, 255, 0.547)
}

.time-unit{
    font-size: 23px;
    color: rgba(101, 101, 101, 0.677);
    margin-left: -8px;
}

.dark .time-unit{
    color: rgba(255, 255, 255, 0.547);
}


.graph-container .h-bar2 {
    height: 20px;
    /* background-color: purple; */
}


.rangePicker{
    margin-left: 10px;
}

.switch2{
    margin-top: 30px;
    margin-left: 30px;
  }
  
  .switch2 p{
    font-size: 14px;
    color: gray;
  }

  .specific-selector{
    margin-left: 30px;
  }