.cont2{
    /* background-color: red; */
    height: 100vh;
    width: 100%;
    background: linear-gradient(to right, #060421, #03022d);
    padding: 20px;
}

.img-cont{
    width: 100%;
}

.logo2{
    height: 40px;
}

.ant-table-thead .ant-table-cell {
    background-color: #03021a !important;
 }


.ant-table-tbody .ant-table-cell {
    background-color: #03021a !important;
}