.modalHeading {
  color: #8284FA !important;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 10px;
}

.weightText {
    font-weight: 700
}

.disabled {
    cursor: not-allowed;
}

.disabled::-webkit-scrollbar{
  display: none;
}

.enabled::-webkit-scrollbar{
  display: none;
}

.horizontal{
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* background-color: red; */
}

.copy{
  margin-left: 10px;
  display: inline-block;
  background-color: none;
}

.icon{
  background-color: #8284fa;
}

.from_content{
  width: 95%;
  /* background-color: red; */
  /* border: 1px solid rgb(74, 66, 78); */
  padding: 5px;
}

.buttons{
  width: 100%;
  height: 60px;
  margin-top: 20px;
  /* background-color: red; */
  text-align: right
}

.button{
  margin-left: 10px;
}

.links{
  margin-right: 10px;
  text-decoration: none;
  color: #8284fa;
}

.tagbutton{
  /* background-color: white; */
  color: black;
  height: 20px;
  width: 100px;
}

.datadiv{
  display: flex;
  box-sizing: border-box;
  /* margin: 20px; */
  padding: 20px;
  width: 100%;
  height: 100vh;
  /* background-color: green; */
}

.left {
  position: relative;
  min-width: 400px;
  flex-grow: 1; /* take up remaining space */
  padding: 10px;
  /* overflow: hidden; */
  /* background-color: blue; */
}
.left::-webkit-scrollbar{
  display: none;
}

.stickytop {
  position: sticky;
  top: 0;
  height: 80px; /* set the height of the sticky bar */
  background-color: lightblue;
}

/* .left .datacontent {
  width: 1000px;
  height: 2000px;
  background-color: blue;
} */

.right {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  min-width: 270px;
  width: 300px;
  padding: 5px;
  /* background-color: yellow; */
}

.right p {
  /* text-align: center;
  width: 80%; */
  font-style: italic;
  margin-bottom: 10px;
  font-size: 18px;
}

.notepad{
  width: 100%;
  height: 500px;
  overflow: hidden;
  /* background-color: red; */
}

.ai{
  width: 100%;
  height: 500px;
  /* background-color: red; */
}

.img{
  width: 30px;
  height: 30px;
}

@media (max-width: 800px) {

  .right {
    /* background-color: blue; */
    display: none;
    /* min-width: 0px;
    width: 0px;
    padding: 0px; */
  }
}

.top{
  margin-bottom: 20px;
}

.select_left{
  float: left;
  text-align: left;
}

.images{
  margin-bottom: 10px;
}

.aiObj {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(255, 255, 255, 0.076);
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 20px;
  /* background-color: red; */
}

.objects {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.img {
  width: 40px;
  height: 40px;
}

.textContainer {
  margin-left: 10px;
}


.textContainer p {
  margin: 0;
  font-size: 14px;
}

.switch{
  width: 100px;
  margin-bottom: 10px;
}

.gray{
  color: gray;
}

.bold{
  font-weight: 500;
}

.badge{
  font-size: 12px !important;
  margin-bottom: 4px !important;
  display: inline-block;
  background-color: rgb(187, 232, 187);
  color: darkgreen;
  padding: 4px;
  border-radius: 6px;
  font-weight: 600;
}

.badge2{
  font-size: 12px !important;
  margin-bottom: 4px !important;
  display: inline-block;
  background-color: rgb(232, 187, 187);
  color: darkred;
  padding: 4px;
  border-radius: 6px;
  font-weight: 600;
}

.pbold{
  font-weight: 600;
}

.pgray{
  color: rgb(195, 195, 195);
}