.invite-card{
	border: 1.5px solid #1b1b55;
	width: 50%;
	margin: 0 auto;
	background-color: #070522;
	border-radius: 8px;
	padding: 75px;
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.invite-card .title{
  	font-size: 33px;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #8282c8, #f7f7f7 );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
	text-align: center;
}

.description{
	font-size: 16px;
	color: rgba(255, 255, 255, 0.614);
	margin-bottom: 22px;
}

.accept-invite{
	width: 100%;
	padding: 12px;
	border-radius: 8px;
	border: none;
	background-color: #8282c8;
	color: white;
	font-weight: 500;
	font-size: 16px;
	margin-top: 20px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	margin-bottom: 60px;
}


.account-setup{
	width: 100%;
	height: 100%;
	display:flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	gap: 20px;
}

.account-setup > h2{
	text-align: center;
}


.form-inputs{
	display: 'flex';
	flex-direction: column;
	margin-top: 20px;
	align-items: center;
	width: 100%;
}

.form-inputs label{
	font-size: 20px;
}

.form-input{
	display: block;
	width: 100%;
	padding: 8px;
	border-radius: 5px;
	color: white;
	background-color: transparent;
	border: 1.5px solid #1b1b55;
	margin-top: 10px;
	margin-bottom: 25px;
	font-size: 20px;
}

.form-button{
	width: 60%;
	padding: 12px;
	border-radius: 8px;
	border: none;
	background-color: #8282c8;
	color: white;
	font-weight: 500;
	font-size: 16px;
	margin-top: 15px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	width: 100%;
}