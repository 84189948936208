.test{
    width: 96%;
    margin-left: 4%;
    margin-top: 40px;
}

.textareas{
    margin-top: 35px;
}

.textareas button{
    margin-top: 15px;
}

.pair{
    margin-bottom: 15px;
}

label{
    margin-bottom: 50px;
}

.finetune-button{
    margin-top: 20px;
    margin-bottom: 100px;
}