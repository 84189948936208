.checkContainer {
    width: 18px;
    height: 18px;
    background: none;
    border: none;
}
  
.checkContainer div {
    margin: 2px;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    border: 2px solid #de4e74;
}
  
.checkContainer svg {
    width: 100%;
    height: 100%;
    color: #4ed924;
}
  

