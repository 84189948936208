* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

body {
    color: #1a1a1a;
    min-height: 100vh;
}

.dark body{
    color: #F2F2F2;
    background-color: #070418;
    /* background-color: #1a1a1a; */
}

button {
    cursor: pointer;
}



/* Ant Design Selectors cannot be stylized in any module.css files */
/* so these selectors are to be placed in the global CSS instead*/


.email-modal .ant-modal-title{
    font-size: 24px;
    font-weight: bold;
}

.email-modal .ant-input{
    padding: 8px;
}

.email-modal .ant-input::placeholder{
    color:#777;
}

.email-modal .react-tagsinput{
    margin: 20px 0;
    background-color: #0e0923;
    border-radius: 5px;
}

.email-modal .react-tagsinput:hover{ border: 2px solid white }

.upload .ant-upload{
    width: 100%;
    padding: 20px;
}

.upload_body{
    display:flex;
    flex-direction: column;
    align-items: center;
    cursor:pointer;
    margin: 0 auto;
}

.upload .ant-upload-select{
    width: 100%;
    margin-top: 20px;
    border: 1px solid rgb(162, 162, 162);
    cursor: pointer;
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: #181818 ;
    padding: 0;
}

.upload .ant-upload-list-item-container{
    display:none;
}


.file-list{
    width: 100%;
    display:flex;
    gap: 20px;
    padding: 20px 0;
    flex-wrap: wrap;
  }
  
  .file-list div{
    position: relative;
    border:1px solid #777;
    padding: 20px;
    border-radius: 12px;
  }
  
  .file-list .remove-file{
    position: absolute;
    right:0;
    top: 10px;
    cursor:pointer;
  }
  
  .file-list a{
    color:white;
    text-decoration: none;
    display:flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor:pointer;
  }

.email_modal_quill .ql-editor, .thread_view_quill .ql-editor{
    height: 200px;
}
