.container {
    width: 100%;
    /* background-color: #0f0f0f; */
    background-color: #24242f;
    border-radius: 20px;
    max-width: 90%;
    /* 736px */
    margin: 0 auto;
    margin-top: 50px;
    /* padding: 0 1rem; */
    padding: 20px;
    position: relative;
  }
  
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  .header > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .header p {
    color: #4EA8DE;
    font-size: 14px;
    font-weight: 700;
  }
  
  .textPurple {
    color: #8284FA !important;
  }
  
  .outlined {
    background: #333333;
    color: #D9D9D9;
    padding: 3px 9px;
    border-radius: 999px;
    font-size: 12px;
    font-weight: 700;
  }
  
  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }