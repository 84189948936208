.privacy{
    /* background-color: red; */
    padding: 50px;
    color: rgba(255, 255, 255, 0.884);
}

.privacy-h{
    font-size: 30px;
    padding-inline-start: 50px;
    margin-bottom: 30px;
}