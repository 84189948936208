.checkContainer {
    width: 18px;
    height: 18px;
    background: none;
    border: none;
}
  
.checkContainer div {
    margin: 2px;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    border: 2px solid #de4e74;
}
  
.checkContainer svg {
    width: 100%;
    height: 100%;
    color: #4ed924;
}
  

.dots{
    background-color: none;
    border: none;
    color: white;
}


.customButton {
    border: none;
    box-shadow: none;
  }

  .open {
    padding: 7px;
    font-weight: 500;
    background-color: #1f1654; /* Example open status color */
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.808);
  }
  
  .closed {
    padding: 7px;
    font-weight: 500;
    background-color: #1f1654; /* Example open status color */
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.808);
  }