.settings-container{
    width: 96%;
    /* background-color: red; */
    margin-left: 4%;
    height: 100vh;
    /* padding: 20px; */
} 

/* .selectors{
    margin-top: 30px;
} */


h1 { 
    margin-top: 30px;
    font-size: 24px; 
    margin-bottom: 5px;
}

.selectors p{
    font-size: 16px;
    color: gray;
}


span{
    font-weight: 600;
    margin-right: 10px;
}

.toggles{
    margin-top: 10px;
    padding: 5px;
}

.margin{
    margin-bottom: 15px;
}

.add{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.mode-toggle {
    /* background-color: red; */
    height: 150px;
    margin-top: 60px;
}

.par{
    color: gray;
}

.par span{
    font-weight: 600;
}

.toggles-all{
  min-height: 160px;
}

.toggles-left {
    float: left;
    width: 50%;
    height: auto;
  }
  
  .toggles-right {
    float: right;
    width: 50%;
    height: auto;
  }

  @media (max-width: 600px) {
    .toggles-left,
    .toggles-right {
      float: none;
      width: 100%;
    }
  }
  
  .toggles {
    margin-bottom: 10px;
  }
  
  .faq{
    /* background-color: blue; */
  }

  .faq h3{
    font-size: 16px;
    color: white;
    margin-bottom: 10px;
  }
  
  .faq-save {
    margin-top: 10px;
    /* float: right; */
    width: 150px;
    margin-bottom: 20px;
    border: solid 1.5px white;
  }

.faq .title {
  margin-top: 15px;
  font-size: 18px;
  opacity: 0.5;
  /* margin-bottom: 2px; */
}

.faq .title-2 {
  margin-top: 15px;
  font-size: 23px;
  /* margin-bottom: 2px; */
}


.faq p{
  font-size: 14px;
  margin-bottom: 5px;
}


.apikeys{
    margin-top: 60px;
}

.key-title{
  font-weight: 500;
  margin-bottom: 4px;
}

.keys{
  margin-top: 20px;
}

.team{
  /* background-color: red; */
  /* opacity: 30%; */
}

.ant-input {
  background-color: #0e0923; /* Change this to your desired background color */
  border: 1px solid #0e0923;
}

.switch{
  margin-top: 30px;
}

.switch p{
  font-size: 14px;
  color: gray;
}

.ppp{
  font-size: 14px;
  color: gray;
}

.upgrade{
  width: 100%;
  border: 0.5px solid #434343;
  display:flex;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
}

.switchh{
  margin-bottom: 8px;
}

.gray{
  color: rgb(144, 143, 143);
}


.inputtt{
  max-width: 40%;
  margin-bottom: 10px;
}

.texttt{
  max-width: 300px;
  padding: 10px;
  margin: 5px;
}

.titleee{
margin-top: 30px;
}

.paraaa{
  font-size: 10px;
}


.all-in-line {
  /* background-color: red; */
  height: 60px;
  display: flex;
  align-items: center;
  gap: 20px;
  /* margin-bottom: 20px; */
  padding: 5px;
}

.all-in-line .the-text-body {
  width: 100%;
  /* background-color: blue; */
  margin-bottom: 10px;
  margin-top: -18px;
  display: flex;
  justify-content: space-between;
}

.disabled-option {
  /* opacity: 0.7; */
}

.automation-item{
  /* background-color: red; */
  margin-top: 4px;
  border: 0.5px solid rgb(36, 16, 63);
  border-radius: 8px;
  padding: 10px;
}

.automations-section{
  margin-top: 15px;
  /* padding: 10px; */
}

.super-edit{
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: 0.5px solid rgb(36, 16, 63);
  border-radius: 8px;
  color: rgb(114, 66, 175);
  font-size: 15px;
  opacity: 0.7;
}

.input-h{
  /* background-color: red; */
}

.inputttt{
  min-width: 300px;
}

.right-of-body{
  margin-top: 22px;
  display: flex;
  align-items: center;
  /* background-color: red; */
}

.margin-button{
  margin-top: 10px;
}

.super-edit-2{
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: 0.5px solid rgb(36, 16, 63);
  border-radius: 8px;
  color: rgb(114, 66, 175);
  font-size: 15px;
}


.save-button {
  display: block;
  margin-top: 10px; /* Adjust the margin as needed */
}

.buttonz{
  margin-right: 15px;
}

.testttttt{
  background-color: red;
}