.header {
    background: #0D0D0D;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    position: relative;
}
.form {
    position: absolute;
    height: 54px;
    bottom: -27px;
    width: 100%;
    max-width: 736px;
    display: flex;
    gap: 8px;
    padding: 0 1rem;
}

.input {
    height: 100%;
    flex: 1;
    color: #fff;
    background: #262626;
    border: 1px solid #0D0D0D;
    border-radius: 8px;
    padding: 0 16px;
    font-size: 16px;
  }
  
  .input::placeholder {
    color: #808080;
  }

  .logout {
    position: absolute;
    display: flex;
    right: 20px;
}