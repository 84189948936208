
.logo{
  width: 300px;

}


.container {
  display: flex;
  height: 100vh;
}

.left {
  flex: 0 0 60%;
}

.login{
  padding-top: 20px;
  /* border: 2px solid red; */
  display:flex;
  flex-direction: column;
  align-items: center;
}

.login .title{
  font-size: 24px;
  font-weight: 600;
  /* background: linear-gradient(90deg, #f7f7f7, #8282c8 ); */
  /* -webkit-background-clip: text; */
  /* background-clip: text; */
  /* color: transparent; */
  color: white;
}

.login .par{
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.684);
  font-style: italic;
}

.login .parr{
  margin-top: 10px;
  margin-bottom: 35px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.684);
}

.button{
  width: 450px;
  border: 0.125rem solid #2a2a58;
  border-radius: 8px;
  color: white;
  background: none;
  padding: 10px;
}

.input{
  background: none;
  /* border: 0.7px solid rgb(164, 151, 237); */
  width: 450px;
  color: white;
  background-color: transparent;
}

.right {
  flex: 1;
}

/* Media query for screens less than 800px width */
@media (max-width: 950px) {
  .container {
    flex-direction: column; /* Stack the divs vertically */
  }

  .left {
    flex: 1; /* Left div will now fill the entire width */
  }

  .right {
    display: none; /* Hide the right div */
  }
}

.buttonlogo{
  width: 24px;
  height: 24px;
}

.buttoncontent{
  display: flex;
  align-items: center;
}

.buttoncontent p{
  flex: 1;
  text-align: center;
  font-size: 16px;
}

.submitbutton{
  width: 450px;
  height: 40px;
  border-radius: 8px;
  border: none;
  background-color: rgb(143, 143, 196);
  color: rgb(233, 230, 230);
  font-weight: 400;
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  margin-bottom: 40px;
}

.divider{
  width: 100%;
  background-color: red;
}

.policy{
  /* width: 100%; */
  /* text-align: center; */
  color: white;
  text-decoration: none;
  font-size: 14px;
  margin-top: 60px;
}

.buttontext{
  font-weight: 500;
}


.input {
  background-color: transparent;
  border: 0.125rem solid #2a2a58;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 17px;
}

.label{
  margin-bottom: 4px;
}


.logintext{
  text-decoration: none;
  color: rgb(143, 143, 196);
}


.headerOr{
  width: 100%;
  margin: 20px auto;
  text-align: center;
  display:flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color:#b8b8e1;
  font-size: 24px;
}

.headerOr:before, .headerOr:after{
  content: '';
  display: block;
  width: 100%;
  background: white;
  border: 0.125rem solid #2a2a58;
  border-radius: 10px
}

.headerOr:before{
  margin-right: 20px
}

.headerOr:after{
  margin-left: 20px;
}

.buttonlink {
  background: none;
  color: white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 4px;
  margin-right: 4px;
}