.icontainer{
    width: 96%;
        /* background-color: red; */
        margin-left: 4%;
        height: 100vh;
        /* padding: 20px; */
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 20px;
    margin-top: 40px;
  }
  
  .block {
    width: 100%;
    height: 175px;
    box-shadow: 0 2px 4px rgba(134, 132, 132, 0.2); /* Add a shadow effect */
    padding: 10px;
    border: 1px solid #ffffff11;
  }
  
  .block .header {
    display: flex;
    width: 100%;
    padding: 0px;
    /* background-color: blue; */
  }
  
  .block .logo img {
    width: 50px;
    height: 50px;
    /* background-color: green; */
  }

  .block .vert{
    margin: 5px;
  }
  
  .block .title {
    /* background-color: red; */
    margin-top: 8px;
  }
  
  .block .connect-button {
    margin-left: auto;
  }

  .block .button{
    border-color: white;
  }

  .block .ibutton2{
    display: inline-block;
    padding: 6px 15px;
    background-color: rgba(240,249,234,255);
    color: rgba(108,159,85,255);
    border: 0px solid darkgreen;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px; /* Adjust the text size */
    font-weight: 500; /* Adjust the text weight */
  }
  
  .block .content {
    margin-top: 10px;
    /* background-color: red; */
  }
  
  .block .content p {
    font-size: 15px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.49);
  }
