.quill-editor-container {
  background-color: transparent;
  /* border: none; Remove the border, if any */
}

.tooo {
  display: flex;
  align-items: center;
  /* padding: 10px; */
  color: white;
  height: 5vh;
  width: 100%;
  border: 1px solid white; /* Remove the border, if any */
}

.tooo p {
  padding: 10px;
}

.tagss{
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: red; */
  overflow-y: auto;
}

.tooo p{
  font-size: 14px;
}

.ql-toolbar {
  background-color: transparent !important;
}

.ql-editor {
  background-color: transparent !important;
}
