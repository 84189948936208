.container {
  width: 100%;
  border-radius: 20px;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.flex {
  display: flex;
  width: 100%;
}

.menu{
  width: 60px;
  /* background-color: green; */
  height: 100vh;
}

.cont{
  flex: 1;
  margin: 45px;
  /* background-color: red; */
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  /* background-color: blue; */
}

.create{
  margin-bottom: 40px;
  display:flex;
  justify-content: space-between;
  align-items: center;
}

.ticketCounter{
  display:flex;
  gap: 20px;
}

.ticketCounter > div{
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 2px solid #1b1b55;
  border-radius: 10px;

}

.create > button{
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #7f88d6;
  font-weight: 500;
  color: #dddeed;
}

.header > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header p {
  color: #4EA8DE;
  font-size: 14px;
  font-weight: 700;
}

.textPurple {
  color: #8284FA !important;
}

.outlined {
  background: #333333;
  color: #D9D9D9;
  padding: 3px 9px;
  border-radius: 999px;
  font-size: 12px;
  font-weight: 700;
}

.list {
  display: flex;
  /* background-color: red; */
  /* overflow-x: scroll; */
  /* height: 100vh; */
  flex-direction: column;
  gap: 12px;
}

.datadiv{
  display: flex;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100vh;
  /* background-color: green; */
}

.left {
  position: relative;
  min-width: 400px;
  flex-grow: 1; /* take up remaining space */
  /* overflow: hidden; */
  /* background-color: blue; */
}
.left::-webkit-scrollbar{
  display: none;
}

.stickytop {
  position: sticky;
  top: 0;
  height: 80px; /* set the height of the sticky bar */
  background-color: lightblue;
}

/* .left .datacontent {
  width: 1000px;
  height: 2000px;
  background-color: blue;
} */

.right {
  display: flex;
  align-items: center;
  /* margin-top: 100px; */
  justify-content: center;
  min-width: 250px;
  width: 300px;
  /* background-color: yellow; */
}

.right p {
  text-align: center;
  width: 80%;
  font-style: italic;
}

.h1{
  margin-bottom: 5px;
  font-weight: 800;
  font-size: 35px;
}

.p {
  color: gray;
  margin-bottom: 25px;
}

.b{
  width: 160px;
}

.p2{
  font-weight: bold;
}

.switch-margin{
  margin-left: 20px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  z-index: 9999; /* Ensure the popup is on top of other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}


.upgrade{
  width: 100%;
  border: 2px solid #1b1b55;
  display:flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.stack{
  width: 83%;
}

.stack h2{
  color: rgba(255, 255, 255, 0.916);
  margin-bottom: 4px;
}
.stack p{
  color: rgba(255, 255, 255, 0.658);
}

.sortButtons{
  display:flex;
  align-items: center;
}

.sortButtons > button{
  display: inline-block;
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #7F88D6;
  color:white;
  text-align: center;
  text-decoration: none;
  border: 0.5px solid #e0f0ff
}

.sortButtons > button:disabled{
  font-weight: bold;
  background-color: #4d5de8;;
  cursor:no-drop;
}

.buttonNewest{
  border-radius: 8px 0 0 8px;
}

.buttonOldest{
  border-radius: 0 8px 8px 0;
}



.container{
  padding-top: 75px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin:0 auto;
}

.onboardingComponent{
  display:block;
  padding: 20px;
  border-radius: 10px;
  border: 1.5px solid #1b1b55;
  background: linear-gradient(to right, #070522, #02012b);
}


.onboardingButton{
  display:flex;
}

.onboardingButton > div{
  display:flex;
  margin-bottom: 30px;
  gap: 20px;
  align-items:center;
  border: 1.5px solid #1b1b55;
  width: auto;
  padding: 20px;
  border-radius: 20px;
}

.onboardingButton button{
  padding: 10px;
  border: none;
  border-radius: 8px;
  background-color: #7f88d6;
  font-weight: 500;
  color: #dddeed;
  font-weight: bold;
}


.videoModal{
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100vh;
  display:flex;
  align-items: center;
  justify-content: center;
}

.modalBackground{
  position:absolute;
  background-color: black;
  width: 100%;
  height: 100vh;
  opacity: 50%;
}

.video{
  position: relative;
  border: 1.5px solid #1b1b55;
  border-radius: 8px;
  max-width: 850px;
  /* height: 80%; */
  padding: 20px;
  background: linear-gradient(to right, #070522, #02012b);
  text-align: center;
}

.clearButton{
  padding: 7px;
  border: none;
  border-radius: 8px;
  background-color: #7f88d6;
  font-weight: 500;
  color: #dddeed;
  width: 40%;
}