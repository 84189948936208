
*{
    font-family: 'Inter', sans-serif;
}


.b{
    /* background-color: blue; */
    margin-left: 30px;
    margin-top: 20px;
    width: 50%;
    padding: 10px;
    border: none;
}


@media (min-width:700px){
    .b{
        /* background-color: green; */
        margin-left: 55px;
        margin-top: 20px;
        width: 50%;
        padding: 10px;
        border: none;
    }
}

@media (min-width:1220px){
    .b{
        /* background-color: red; */
        margin-left: 80px;
        margin-top: 20px;
        width: 50%;
        padding: 10px;
        border: none;
    }
}