.react-tagsinput {
    background-color: transparent;
    /* border: 1px solid #ccc; */
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
  }
  
  .react-tagsinput--focused {
    border-color: #a5d24a;
  }
  
  .react-tagsinput-tag {
    background-color: #7f88d682;
    border-radius: 4px;
    /* border: 1px solid #ced0ef; */
    color: #ced0ef;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }
  
  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
  }
  
  .react-tagsinput-tag a::before {
    content: " ×";
  }
  
  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 80px;
  }