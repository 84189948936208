
.background{
    /* background-color: #ffffff;
    color: #111111; */
}

.primary {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    color: #0f0f0f;
    font-family: "Helvetica";
    /* height: 105vh; */
    height: 100%;
    float: left;
    /* width: 210px; */
    width: 60px;
    /* overflow: hidden; */
}

.dark .primary{
    /* background-color: #0f0f0f; */
    background-color: #040110;
    color: #f3f3f3;
}

.logic{
    /* background-color: blue; */
    height: 100vh;
    margin-left: 30px;
    padding: 20px;
}

.logic .logic-in-logic {
    margin-left: 30px;
}

.logic .header2 .title {
    padding-top: 10px;
    font-weight: 800;
    font-size: 35px;
}

.logic .header2 .par {
    padding-top: 7px;
    color: gray;
}

.header2{
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding-left: 20px;
    position: relative;
}

.secondary{
    /* padding: 22px; */
}

.header {
    padding-left: 10px;
    padding-top: 22px;
    /* justify-content: space-between; */
}

.header .title{
    font-size: 16px;
    display: inline-block;
}

/* .header .icon {
    float: right;
    margin-right: 20px;
    margin-top: 2px;
} */

.icon{
    margin-top: 12px;
    margin-right: 5px;
    color: #0e0e0e;
}

.icon2{
    margin-right: 5px;
    margin-top: 12px;
    color: #0e0e0e;
}

.dark .icon2{
    color: white;
}

.menu{
    padding-left: 9px;
    padding-right: 15px;
    position: relative;
}

.menu-account{
    position: absolute;
    bottom: 0;
    padding-left: 8px;
    margin-bottom: 20px;
}

.button2 {
    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.pf{
    border-radius: 50%;
    width: 40px;
    height: 40px;
}

.b1{
    /* background-color: #242424; */
    background-color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #0e0e0e;
    display: inline-block;
    width: 100%;
    height: 5vh;
    margin-bottom: 5px;
    text-align: left;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Helvetica";
}

.b2{
    /* background-color: #242424; */
    background-color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #0e0e0e;
    display: inline-block;
    width: 100%;
    height: 5vh;
    margin-bottom: 5px;
    text-align: left;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    font-family: "Helvetica";
}

.dark .b2{
    background-color: #0e0e0e;
    color: white;
}

/* .b1:hover > .icon{
    color: #0e0e0e;
}

.b1:hover{
    background-color: #f3f4f6;
} */

.b2:hover > .icon2{
    /* background-color: #f3f4f6; */
    color: #0e0e0e;
}

.b2:hover{
    background-color: #f3f4f6;
    /* margin-left: 4px; */
}

.chirpish{
    border-radius: 50%;
    /* padding: 5px; */
}