
.d-container{
    display: flex;
    /* width: 100%; */
    height: 100%;
    /* height: 105vh; */
    /* background-color: yellow; */
}

.left {
    width: 60px;
    background-color: red;
  }
  
  .middle {
    min-width: 250px;
    /* flex-grow: 1; */
    /* background-color: green; */
  }
  
  .right {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-grow: 1;
    /* background-color: blue; */
  }

  .right .center {
    /* margin: 25px; */
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: red; */
  }



/* Media query for screens wider than 800px */
@media screen and (min-width: 900px) {
    .middle {
        min-width: 350px;
        /* flex-grow: 1; */
        /* background-color: green; */
      }
  }