
.h3-end{
    margin-bottom: 10px;
    color: rgba(255, 255, 255, 0.933);
}

.p-end{
    color: rgba(255, 255, 255, 0.86);
}

.submit-end{
    display: block;
    width: 70%;
    padding: 12px;
    border-radius: 8px;
    border: none;
    background-image: linear-gradient(135deg, #7368c7, #292654);
    color: #fff; /* Set text color to white for better visibility */
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    text-align: center;
}

.onboarding-container{
	height: 100%;
	display:flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	gap: 20px;
    text-align: center;
}